<template>
	<div class="page-container">
		<div class="search-form">
			<el-form ref="form" :inline="true"  :model="search_key" prop="">
				<el-form-item label="">
					<el-input v-model="search_key.receipt_number" placeholder="输入关键字搜索"></el-input>
				</el-form-item>
				
			<el-button type="primary" @click="getData()">查询</el-button>
			<el-button type="primary" @click="handleExport()">导出全部</el-button>
			</el-form>
		</div>
		 <div style="margin-bottom:10px;">
			<template v-if="!batchStatus">
				<el-button size="small" type="" @click="handleAdd()">添加</el-button>
			</template>
			<template v-else>
				<!-- <el-button size="small" type="" @click="handlePrint()">打印</el-button> -->
				<el-button size="small" type="" @click="handleExport()">导出</el-button> 
				<el-button size="small" type="danger" plain @click="handleDel()">删除</el-button>
			</template>
		 </div>
		 <el-table
			v-loading="loading"	
			element-loading-text="查询中"
			:data="tableData" 
			 :row-key="getRowKeys"
			  @selection-change="handleSelectionChange"
			style="width: 100%;">
			<el-table-column
			type="selection"
			:reserve-selection="true"
			width="55">
			</el-table-column>
			<template v-if="expandStatus">
			<el-table-column  type="expand">
				<template  slot-scope="props">
					<el-table	
					style="width: 90%;margin: 0 auto;"
					:header-cell-style="{height:'35px',background: '#F2F6FC',padding: '0',fontSize:'12px'}"
					:row-style="{height:'40px'}"
					 :cell-style="{padding: '0'}"
					border
					:data="props.row.asset_list">
						<template v-for="item in assetTheadData">
							<el-table-column :width="item.width" :key="item.field_name" :label="item.label">
								<template slot-scope="scope">
									<div v-if="item.field_name == 'cate_id'" >
										<span>{{scope.row['cate_name']}}</span>
									</div>
									<div v-if="item.field_name == 'location_id'" >
										<span>{{scope.row['location_name']}}</span>
									</div>
									<div v-if="item.type == 4">
										<span v-if="scope.row[item.field_name] == '-' || !scope.row[item.field_name]" >{{scope.row[item.field_name]}}</span>
										<el-image v-else style="width: 35px;height: 35px;" v-for="(imgItem,imgIndex) in scope.row[item.field_name]" :src="imgItem"></el-image>
									</div>
									<span v-if="
											item.type != 4 &&
											item.field_name !== 'location_id'
											&& item.field_name !== 'cate_id'">{{scope.row[item.field_name]}}</span>
								</template>
							</el-table-column>
						</template>
					</el-table>
				</template>
			</el-table-column>
			</template>
			<template v-for="item in theadData">
				<el-table-column :key="item.field_name" :label="item.label">
					<template slot-scope="scope">
						<template v-if="item.select_data">
							<span style="color: #F56C6C;">{{item.select_data[scope.row[item.field_name]]['name']}}</span>
						</template>
						<span v-if="!item.select_data">{{scope.row[item.field_name]}}</span>
					</template>
				</el-table-column>
			</template>
			
			<el-table-column
				label="操作"
				min-width="150px"
				max-width="200px"
				>
				<template slot-scope="scope">
					<!-- <template> -->
						<el-button  size="small" @click="handleApproval(scope.row.id)">审批信息</el-button>
						<el-button  size="small" v-if="scope.row.status == 4" @click="handleCheck(scope.row.id)">待验收</el-button>
						<!-- <el-button  size="small" @click="handleEdit(scope.row)">修改</el-button> -->
						<!-- <el-button  size="small" @click="handlePrint(scope.row)">打印</el-button> -->
					<!-- </template> -->
						<el-button  size="small" type="danger" plain @click="handleDel(scope.row.id)">删除</el-button>
				</template> 
			</el-table-column>
		 </el-table> 
		<pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
		<AddVue ref='childDialog'></AddVue>
		 <ApprovalVue ref="approval"></ApprovalVue>

	</div>
	
</template>
<script>
import AddVue from './Add.vue'
import ApprovalVue from './ApprovalDrawer.vue'
export default {
	inject:['reload'],
    components:{
        AddVue,
		ApprovalVue
    },
   data() {
      return {
		  loading:true,
		  search_key:{
			  receipt_number:''
		  },
		  batchStatus:false,
		  cardName:'',	
		  cardType:2,
		  getRowKeys(row){
		      return row.id
		  },
		  tableData:[],
		  currentPage:1,
		  pagesize:10,
		  total:0,
		  theadData:[],
		  assetTheadData:[],
		  addDialogTitle:'',
		  rUrl:{
			  index:'',
			  add:'',
			  del:'',
			  edit:'',
			  export:''
		  },
		  expandStatus:false,
		  storageType:0
      }
   },
   created(){
       if(this.$route.query.activeName) this.activeName =  this.$route.query.activeName
     	if(this.$route.query.status) this.search_key.status = this.$route.query.status
   },
   mounted(){
		
   },
   methods:{
       getData(){   //表格数据
            var arr = []
			this.search_key.card_name = this.cardName
			this.search_key.page = this.currentPage-1
			this.search_key.limit = this.pagesize
            this.$api.post(this.rUrl.index,this.search_key,res=>{
               if (res.code == 200) {
                   this.tableData =  res.data.list??[]
				   this.theadData =  res.data.thead
				   this.assetTheadData =  res.data.asset_thead
                   this.total =  res.data.count
               }else{
				  this.$message.error(res.msg)
			   }
			   this.loading =  false
           })
       },

         //监听表格多选框
       handleSelectionChange(val){
           this.multipleSelection  = []
           var list = val;
            list.forEach(item=>{
                this.multipleSelection.push(item.id )
            })
			if(this.multipleSelection.length > 0){
				this.batchStatus = true
			}else{
				this.batchStatus = false
			}
			console.log('multi:',this.multipleSelection)
       },
		handleAdd(){
			this.$nextTick(()=>{
				this.$refs.childDialog.cardName = this.cardName
				this.$refs.childDialog.addUrl  = this.rUrl.add
				this.$refs.childDialog.editUrl =  this.rUrl.edit
				this.$refs.childDialog.storageType = this.storageType
				this.$refs.childDialog.handleAdd()
			})
			
		},
	   handleEdit(id){
		   this.$refs.editChildDialog.handleEdit(id)
	   },

		handleApproval(id){
			console.log(this.storageType)
			// this.$refs.approval.storageType = this.storageType
			this.$refs.approval.handleApproval(id,this.storageType,this.cardName)
		},
		handleExport(){
			var id = this.multipleSelection?this.multipleSelection.join(','):''
			location.href = '/api/admin'+this.rUrl.export+'/?id='+id
		},
		handlePrint(){},

        getCurrPage(val){   //获取当前页
            this.currentPage =  val
			this.loading = true
            this.getData()
        },
        getPageSize(val){   //获取每页条数
            this.pagesize =  val
            this.getData()
        },
		
		handleDel(id=0){
			let ids = []
			if(id > 0){
				ids.push(id)
			}else{
				ids =  this.multipleSelection
			}
			this.$alert("确定删除选中数据吗？", "删除提示", {
			    showConfirmButton: true,
			    showCancelButton: true,
			    callback: action => {
			        if (action == "confirm") {
			            this.$api.delete(this.rUrl.del, {
							id:ids
						}, res => {
			                if (res.code == 200) {
			                    this.$message.success(res.msg);
			                    this.reload();
			                }
			            });
			        }
			        else if (action == "cancel") {
			            this.reload();
			        }
			    }
			});
		},
		handleCheck(id){
			this.$alert("请确定资产已维修完毕，验收后资产恢复闲置状态？", "验收提示", {
			    showConfirmButton: true,
			    showCancelButton: true,
			    callback: action => {
			        if (action == "confirm") {
			            this.$api.put('AssetMaintain/check', {
							id:id
						}, res => {
			                if (res.code == 200) {
			                    this.$message.success(res.msg);
			                    this.reload();
			                }
			            });
			        }
			        else if (action == "cancel") {
			            this.reload();
			        }
			    }
			});
		}
   }

}
</script>
<style lang="scss">

  .bg-purple {
      padding:10px;
    background:  #d9ecff;
  }
  .bg-purple-light {
    background: #d9ecff;
  }

</style>