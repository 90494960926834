<template>
<div>
	<el-drawer  title="审批信息" :visible.sync="dialog" 
	:append-to-body="true"
	:modal-append-to-body="false"  
	custom-class="demo-drawer" 
	ref="drawer" 
	size="20%" >
		<div class="approval-box">
			<div class="appro-head">
				<span class="s-title">{{info.title}}</span>
				<div class="co-title" style="position: relative;">
					<div style="width: 35%;">{{info.co_name}}</div>
					<!-- <div>{{info.status<1?'待处理':(info.status == 2?'已撤销':'已完结')}}</div> -->
					<div style="position: absolute;right: 30px;">
						<img v-if="info.status < 1" src="/images/asset/progress.png" alt="处理中"/>	
						<img v-if="info.status == 1 " src="/images/asset/closed.png" alt="已完结"/>
						<img v-if="info.status == 2 " src="/images/asset/withdraw.png" alt="已撤销"/>
						<img v-if="info.status == 3 " src="/images/asset/reject2.png" alt="已驳回"/> 
					</div>
				</div>
			</div>
			
			
			<div class="appro-info" >
				<template v-for="(item,index) in storageData"> 
					<span class="s-title">{{item.title}}</span>
					<span class="s-value">{{item.value}}</span>
				</template>
				
				<div class="appro-asset" v-if="approvelType == 1" >
					<template v-for="(item,index) in assetData">
						<p>资产列表{{index + 1}}</p>
						<template v-for="item2 in item">
							<span class="s-title">{{item2.title}}</span>
							<span class="s-value">{{item2.value}}</span>
						</template>
					</template>
				</div>
				
				<div class="appro-asset" v-if="approvelType == 2">
					<template v-for="(item,index) in info.items">
						<p>物料列表{{index + 1}}</p>
						<!-- <template v-for="item2 in items"> -->
							<span class="s-title">物料名称</span>
							<span class="s-value">{{item.name}}</span>
							<span class="s-title">物料编码</span>
							<span class="s-value">{{item.mtl_code}}</span>
							<span class="s-title">物料分类</span>
							<span class="s-value">{{item.cate_name}}</span>
							<span class="s-title">品牌</span>
							<span class="s-value">{{item.brand}}</span>
							<span class="s-title">规格型号</span>
							<span class="s-value">{{item.model}}</span>
							<span class="s-title">入库单价</span>
							<span class="s-value">{{item.total_prices/(item.stock+item.blocked_stock)}}</span>
							<span class="s-title">计量单位</span>
							<span class="s-value">{{item.unit}}</span>
							<span class="s-title">总价</span>
							<span class="s-value">{{item.total_prices}}</span>
							<span class="s-title">物料照片</span>
							<span class="s-value"  v-if="item.picture">
								<img :src="item.picture" style="width: 45px;height: 45px;"/>
							</span>
						<!-- </template> -->
					</template>
				</div>
				
				<div class="appro-asset" v-if="approvelType == 4">
					<template v-for="(item,index) in assetData" >
						<p>列表{{index + 1}}</p>
						<!-- v-if="info.card.apply_type == 1" -->
						<template v-for="item2 in item" >
							<span class="s-title">{{item2.title}}</span>
							<span class="s-value">{{item2.value}}</span>
						</template> 
					<!-- 	<template v-if="info.card.apply_type == 2">
							<span class="s-title">物料名称</span>
							<span class="s-value">{{item.name}}</span>
							<span class="s-title">物料编码</span>
							<span class="s-value">{{item.mtl_code}}</span>
							<span class="s-title">物料分类</span>
							<span class="s-value">{{item.cate_name}}</span>
							<span class="s-title">品牌</span>
							<span class="s-value">{{item.brand}}</span>
							<span class="s-title">规格型号</span>
							<span class="s-value">{{item.model}}</span>
							<span class="s-title">入库单价</span>
							<span class="s-value">{{item.total_prices/(item.stock+item.blocked_stock)}}</span>
							<span class="s-title">计量单位</span>
							<span class="s-value">{{item.unit}}</span>
							<span class="s-title">总价</span>
							<span class="s-value">{{item.total_prices}}</span>
						</template> -->
					</template>
				</div>
			</div>
			<!-- 流程 -->
			<div class="process-box" v-if="info.approval">  
				<template  v-for="(approval,index) in info.approval"> 
					<div class="admin-info">
							<img style="width: 45px;height: 45px;border-radius: 5px;border: 1px solid  #EBEEF5;" :src="approval.avatar"/>
						<div style="width: 55%;">
							<p>{{approval.initiator_status?'发起人':'审批人'}}</p>
							<p style="font-size: 12px;color: #999;">{{approval.name}}</p>
							<p style="font-size: 12px;color: #999;" v-if="approval.remind_status == 1">{{approval.status<1?'（审批中）':(approval.status == 1?'（审批通过）':'（已拒绝）')}}</p>
							<p style="font-size: 12px;color: #999;">{{approval.remark}}</p>
						</div>
						<span style="font-size: 12px;color: #999;">{{approval.update_time}}</span>
					</div>
					<div style="min-height: 50px;width: 2px;background-color: #c7c7c7;margin: 5px 0 5px 20px;" v-if="index < (info.approval.length-1)"></div>
				</template>
			</div>
		</div>
		<template v-if="info.status == 0">
			<div  class="appro-footer" style="display: flex;">
				<div style="margin-right: 20px;" v-if="isInitiatorStatus">
					<el-button type="text" v-if="info.status == 0" @click="handleUrge">催办</el-button>
					<el-button type="text" @click="revocation" v-if="info.status == 0">撤销</el-button>
				</div> 
				<div v-if="approval_btn_status" style="width: 65%;margin-left: 10px;">
					<el-button style="border-radius: 10px 0 0 10px;margin: 0;border: 1px solid #409EFF;color: #409EFF;" @click="showRemarkDialog(2)">驳回</el-button>
					<el-button style="border-radius: 0 10px 10px 0;margin: 0;" type="primary" @click="showRemarkDialog(1)">同意</el-button> 
				</div>
			</div>
		</template>
		<el-dialog :title="agreeStatus==1?'同意审批':'驳回审批'" :visible.sync="dialogRemarkVisible" :append-to-body="true">
		  <el-form :model="form">
		    <el-form-item label="输入审批意见" label-width="120px">
		     <el-input type="textarea"  rows="5"  v-model="form.remark" placeholder=""></el-input>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="confirmAgree">{{agreeStatus==1?'确定同意':'确定驳回'}}</el-button>
		  </div>
		</el-dialog>
	</el-drawer>
	
</div>
</template>

<script>
export default {
	inject:['reload'],
    data() {
        return {
			dialog:false,
			cardName:'',
			storageData:[],
			info:[],
			assetData:[],
			storageType:0,
			approval_btn_status:0,
			dialogRemarkVisible:false,
			agreeStatus:0,
			isInitiatorStatus:false,		//当前账号是否是发起人
			approvelType:1,		//1-资产表单审批  2-耗材表单审批  3-员工申请审批
			form:{
				id:'',
				remark:''
			}
        }
    },
    created() {
		
    },
    mounted() {
		
    },
    methods:{
		handleApproval(id,type,cardName){
			this.form.id = id
			this.cardName =  cardName
			if(this.approvelType == 1){		//资产表单审批
				this.$api.get('AssetDist/getApproval', {
					id:id,
					type:this.storageType,
					card_name:this.cardName
					}, res => {
					if (res.code == 200) {
						this.info =  res.data.info
						this.storageData  = res.data.info.storage_data
						this.assetData  = res.data.info.asset_list
						this.approval_btn_status =  res.data.approval_btn_status
						this.isInitiatorStatus =  res.data.is_initiator	
						this.dialog =  true
					} 
				})	
			}else if(this.approvelType == 2){
				this.$api.get('CseStorage/getApproval', {
					id:id,
					type:this.storageType,
					card_name:this.cardName
					}, res => {
					if (res.code == 200) {
						this.info =  res.data.info
						this.storageData  = res.data.info.storage_data
						this.assetData  = res.data.info.asset_list
						this.approval_btn_status =  res.data.approval_btn_status
						this.isInitiatorStatus =  res.data.is_initiator	
						this.dialog =  true
					} 
				})	
			}else if(this.approvelType  == 4){
			
				this.$api.get('Staff/getApproval', {
					id:id,
					}, res => { 
					if (res.code == 200) {
						this.info =  res.data.info
						this.storageData  = res.data.info.storage_data
						this.assetData  = res.data.info.asset_list
						this.approval_btn_status =  res.data.approval_btn_status
						
						this.dialog =  true
					} 
				})	
			}
			
		
		},
		revocation(){
			this.$alert("确定撤销该审批吗？撤销后不可恢复", "撤销提示", {
			    showConfirmButton: true,
			    showCancelButton: true,
			    callback: action => {
			        if (action == "confirm") {
						// var url = 'AssetDist/revocation'
						// if(this.approvelType == 2){
						// 	url  = 'AssetApproval/revocation'
						// }
						var url  = 'AssetApproval/revocation' 
			            this.$api.put(url, {
							id: this.info.id ,
							approval_type:this.approvelType
							}, res => {
			                if (res.code == 200) {
			                    this.$message.success(res.msg);
			                    this.reload();
			                }
			            });
			        }
			        else if (action == "cancel") {
			            this.reload();
			        }
			    }
			});
		},
		showRemarkDialog(type){
			this.agreeStatus = type
			this.dialogRemarkVisible = true
		},
		confirmAgree(){	//审批提交
			this.$api.post("AssetApproval/agree", { 
				id:this.form.id,
				type:this.approvelType,
				remark:this.form.remark,
				status:this.agreeStatus,
				card_name:this.cardName,
			}, res => {
			    if (res.code == 200) {
			        this.$message.success(res.msg);
			        this.reload();
			    }else{
					this.$message.error(res.msg)
				}
			});
		},
		handleUrge(){	//催促审批
			this.$api.post("AssetApproval/urgeApproval", {
				id:this.form.id,
				type:this.approvelType,
				card_name:this.cardName
			}, res => {
			    if (res.code == 200) {
			        this.$message.success('已催办，待审批');
			        this.reload();
			    }else{
					this.$message.error(res.msg)
				}
			});
		}
    },
}
</script>
<style  lang="scss">
	.approval-box{
		width: 100%;
		height: 100%;
		background-color: #F2F6FC;
		padding: 10px;
		font-size: 14px;
		margin-bottom: 120px;
		.appro-head,.appro-info,.process-box{
			background: white;
			margin-bottom: 10px;
			border-radius: 5px;
			text-indent: 20px;
		}
		.appro-head{
			.s-title{
				display: block;
				height: 40px;
				line-height: 40px;
				font-size: 16px;
				font-weight: 600;
			}
			.co-title{
				color: #909399;
				height: 30px;
				line-height: 30px;
				display: flex;
			}
		}
		.appro-info{
			span{
				display: block; 
				height: 30px;
				line-height: 30px; 
			}
			.s-title{
				color: #909399;
			}	
			.appro-asset{
				margin-top: 20px;
				padding-top: 5px;
				p{
					width: 95%;
					margin: 0 auto;
					margin-top: 5px;
					height: 30px;
					line-height: 30px;
					background: #F2F6FC;
				}
			}
		}

	}
	.appro-footer{
		width: 100%;
		background-color: white;
		height: 80px;
		line-height: 80px;
		position: fixed;
		bottom: 0;
		box-shadow: 0px 3px 3px #F2F6FC inset;
		padding-left: 10px;
	}
</style>

<style scoped lang="scss">
	
	.process-box{
		padding: 20px 0px 20px 10px;
		.admin-info{
			display: flex;
			align-items: center;
			p{
				margin: 5px;
			}
			
		}
	}
</style>