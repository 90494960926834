<template>
    <div class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>  
        <div class="page-desc">
			资产维修：资产损坏后，管理员提交资产维修单，配合审批流程，记录资产的维修情况。
         </div>   
		<TableVue ref="c_table"></TableVue>
	</div>   
</template>
<script>
import TableVue from './Common/Table.vue'
export default {
    components:{
		TableVue
    },
	 inject:['reload'],
   data() {
      return {
		  // tableTitle:'资产维修',
		  // storageType:10
      }
   },
   created(){
		this.$nextTick(()=>{
			this.$refs.c_table.rUrl = {
				index:'/AssetMaintain/index',
				add:'/AssetMaintain/add',
				edit:'/AssetMaintain/edit',
				del:'/AssetMaintain/del',
				export:'/AssetMaintain/export'
			} 
			this.$refs.c_table.cardName =  '资产维修单'
			this.$refs.c_table.storageType =  8
			this.$refs.c_table.expandStatus = true
			this.$refs.c_table.getData();
		})
   },
   mounted(){
		
   },
   methods:{
		
   }

}
</script>

</style>